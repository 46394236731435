//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import axios from "axios";
import Popup from "@/components/Popup";

/**
 * Rbac component
 */
export default {
  components: {
    Popup,
    Layout,
    PageHeader,
  },
  data() {
    return {
      roleList: [],
      permList: [],
      newRole: {
        roleName: '',
        roleDescr: ''
      },
      newPerm: {
        permName: '',
        permDescr: ''
      },
      submitted: false,
      addPermInRole: null, // РОЛЬ в которую будет добавляться permission
      permsModel: '',
      roleModel: '',
      addPermPopup: {
        show: false,
        closeButton: "Закрыть",
        actionButton: "Подтвердить",
        actionClass: "btn-success"
      },
      addRolePopup: {
        show: false,
        closeButton: "Закрыть",
        actionButton: "Подтвердить",
        actionClass: "btn-success"
      },
      title: "RBAC",
      items: [
        {
          text: "Управление",
        },
        {
          text: "RBAC",
          active: true
        }
      ]
    };
  },
  validations: {
    newRole: {
      roleName: { required, minLength: minLength(3), maxLength: maxLength(30) },
      roleDescr: { required, maxLength: maxLength(350) }
    },
    newPerm: {
      permName: { required, minLength: minLength(3), maxLength: maxLength(30) },
      permDescr: { required, maxLength: maxLength(350) }
    },
  },
  computed: {
    freePerm(){
      let result = [];
      if(this.addPermInRole){
        for(let role in this.roleList){
          if(this.roleList[role].name === this.addPermInRole){
            for(let all in this.permList){
              let z = false;
              for(let rPerm in this.roleList[role].children){
                if(this.roleList[role].children[rPerm].name == this.permList[all].name){
                  z = true;
                }
              }
              if(!z){
                result.push(this.permList[all]);
              }
            }
          }
        }
      }else{
        for(let key in this.permList){
          result.push(this.permList[key]);
        }
      }
      return result;
    },
    freeRole(){
      let result = [];
      for(let role in this.roleList){
        if(this.roleList[role].name !== this.addPermInRole){
          let z = false;
          // Еще один поиск уже внутри присвоенных ролей в ролях
          for(let roleTwo in this.roleList){
            if(this.roleList[roleTwo].name === this.addPermInRole){
              for(let rPerm in this.roleList[roleTwo].children){
                if(this.roleList[roleTwo].children[rPerm].name === this.roleList[role].name){
                  z = true;
                }
              }
            }
          }
          if(!z){
            result.push(this.roleList[role]);
          }
        }
      }
      return result;
    }
  },
  methods: {
    closeAddPermPopup(){
      this.addPermPopup.show = false;
    },
    showAddPermPopup(){
      this.addPermPopup.show = true;
    },
    addPermPopupSubmit(){
      let formData = new FormData();
      formData.append("role_name", this.addPermInRole);
      formData.append("perm_name", this.permsModel);
      axios
          .post(`/v1/role/add-perm-in-role`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.$store.dispatch("addNotification", {
                text: 'Добавлено',
                time: 2,
                color: "success"
              });
              this.getAll();
              this.closeAddPermPopup();
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    addPermInRoleFunc(name){
      this.addPermInRole = name;
      this.showAddPermPopup();
    },
    showAddRolePopup(){
      this.addRolePopup.show = true;
    },
    addRoleInRoleFunc(name){
      this.addPermInRole = name;
      this.showAddRolePopup();
    },
    closeAddRolePopup(){
      this.addRolePopup.show = false;
    },
    addRolePopupSubmit(){
      let formData = new FormData();
      formData.append("role_name", this.addPermInRole);
      formData.append("child_role", this.roleModel);
      axios
          .post(`/v1/role/add-role-in-role`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.$store.dispatch("addNotification", {
                text: 'Добавлено',
                time: 2,
                color: "success"
              });
              this.getAll();
              this.closeAddRolePopup();
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    submitNewRole(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newRole.$touch();
      if(!this.$v.newRole.$invalid){
        let formData = new FormData();
        formData.append("name", this.newRole.roleName);
        formData.append("description", this.newRole.roleDescr);
        axios
            .post(`/v1/role/add-role`, formData)
            .then(resp => {
              if (resp.data.result === "ok") {
                this.$store.dispatch("addNotification", {
                  text: 'Добавлено',
                  time: 2,
                  color: "success"
                });
                this.getAll();
              }
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },
    submitNewPerm() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newPerm.$touch();
      if(!this.$v.newPerm.$invalid){
        let formData = new FormData();
        formData.append("name", this.newPerm.permName);
        formData.append("description", this.newPerm.permDescr);
        axios
            .post(`/v1/role/add-permission`, formData)
            .then(resp => {
              if (resp.data.result === "ok") {
                this.$store.dispatch("addNotification", {
                  text: 'Добавлено',
                  time: 2,
                  color: "success"
                });
                this.getAll();
              }
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },
    deletePermission(name){
      let formData = new FormData();
      formData.append("name", name);
      axios
          .post(`/v1/role/remove-permission`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.$store.dispatch("addNotification", {
                text: 'Удалено',
                time: 2,
                color: "success"
              });
              this.getAll();
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    deleteRole(name){
      let formData = new FormData();
      formData.append("name", name);
      axios
          .post(`/v1/role/remove-role`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.$store.dispatch("addNotification", {
                text: 'Удалено',
                time: 2,
                color: "success"
              });
              this.getAll();
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    removeChild(roleName, childName, type){
      let formData = new FormData();
      formData.append("role_name", roleName);
      formData.append("child_name", childName);
      formData.append("child_type", type);
      axios
          .post(`/v1/role/remove-child`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.$store.dispatch("addNotification", {
                text: 'Удалено',
                time: 2,
                color: "success"
              });
              this.getAll();
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getAll(){
      axios
          .post(`/v1/role/get-all`)
          .then(resp => {
            this.roleList = resp.data.roles;
            this.permList = resp.data.perm;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getAll();
  }
};
